const spanish = {
    welcomeMsg: "Bienvenido",
    labelAction: "Acción",
    labelAlmost: "Ya casi has llegado!",
    labelAnalyze: "Analizar",
    labelAnalyzed: "Analizado",
    labelAnInconsistencyWasIdentifiedInTheDocument: "Se identificó una inconsistencia en el documento. Vuelva a procesarlo para obtener mejores resultados.",
    labelApply: "Aplicar",
    labelApplyQuestionnaire: "Aplicar cuestionario",
    labelApplyingQuestionnaire: "Aplicando el cuestionario",
    labelApplyingQuestionnaireWait: "Aplicando el cuestionario, por favor espere.",
    labelAskTheAi: "Pregúntale a la IA",
    labelAttentionDependingOnTheSpeed: "Atención, dependiendo de la velocidad de su conexión a Internet y del tamaño del archivo subido, esta operación puede tardar unos minutos.",
    labelAttentionPDFDisplayFailed: "Se produjo un error al cargar el PDF. Haga clic aquí para intentarlo de nuevo",
    labelAll: "Todo",
    labelBack: "Volver",
    labelCancel: "Cancelar",
    labelConfirm: "Confirmar",
    labelConsult: "Consultar",
    labelCreate: "Crear",
    labelClose: "Cerrar",
    labelCloseHistory: "Cerrar historia",
    labelCloseSidebar: "Cerrar barra lateral",
    labelClearSelection: "Selección clara",
    labelCharacter: "carácter",
    labelCharacters: "caracteres",
    labelCopy: "Copiar texto",
    labelDarkTheme: "Tema Oscuro",
    labelDelete: "Borrar",
    labelDeleteHistory: "Borrar historial",
    labelDescription: "Descripción",
    labelDescriptionDocumentNote: "Descripción (única para varios documentos)",
    labelDescriptionOrQuestionId: "Descripción, ID o ingresa una nueva pregunta para registrarte",
    labelDocumentTypeAlreadyExists: "Este tipo de documento ya existe",
    labelDocumentTypeError: "Tipo de documento no válido",
    labelDocuments: "Documentos",
    labelDocumentName: "Nombre del documento",
    labelDocumentType: "Tipo de documento",
    labelDocumentNameOrDescription: "Nombre o descripción del documento",
    labelDocumentHasAlreadyBeenStandardizedPreviously: "El documento ya ha sido estandarizado anteriormente. <br/> Espere, será redirigido a la página de inicio.",
    labelDownloadCsv: "Descargar CSV",
    labelDownloadSuccessfully: "Descargar con éxito",
    labelDocumentTranscript: "Transcripción de documentos",
    labelDuplicatedQuestionnaire: "Nombre del cuestionario duplicado",
    labelExpand: "Expandir",
    labelEdit: "Editar",
    labelEdited: "Editado",
    labelEditedFail: "Error al editar",
    labelEditQuestion: "Editar pregunta",
    labelEditType: "Editar tipo",
    labelExceedDesc: "La descripción superó el límite de 250 caracteres",
    labelExpandHistory: "Ampliar historia",
    labelFillInThisField: "Complete este campo.",
    labelFindingTheBestAnswer: "Encontrar la mejor respuesta",
    labelFailedToNormalize: "No se pudo normalizar",
    labelFailedNoResponse: "Falló, no hay respuesta del servidor.",
    labelFailedToLoadHistory: "No se pudo cargar el historial.",
    labelFailedToApplyQuestionnaire: "No se pudo aplicar el cuestionario.",
    labelGeneralInformation: "Informaciones generales",
    labelGoHome: "Ir a home",
    labelHistoric: "Histórico",
    labelInclusionDate: "Fecha de inclusión",
    labelListing: "Listado",
    labelLoading: "Cargando",
    labelLines: "Líneas por página",
    labelLoadingTheText: "Cargando el texto",
    labelLoadingFilePleaseWait: "Cargando archivo, por favor espere",
    labelManage: "Administrar",
    labelMostRecent: "Más reciente",
    labelMostOlder: "Más viejo",
    labelModel: "Modelos",
    labelName: "Nombre",
    labelNew: "Nuevo",
    labelNewType: "Crear tipo",
    labelNewQuestion: "Crear pregunta",
    labelNewQuestionnaire: "Crear cuestionario",
    labelNotice: "Aviso",
    labelNoFileChosen: "Ningún archivo elegido",
    labelNoQuestionsRegistered: "No hay preguntas registradas",
    labelNoMoreQuestionsAvailable: "No hay más preguntas disponibles",
    labelNoDocumentsWereFound: "No se encontraron documentos",
    labelNoDocumentTypeWasFound: "No se encontró ningún tipo de documento",
    labelNoQuestionsWereFound: "No se encontraron preguntas",
    labelNoQuestionsWereSelected: "No se seleccionaron preguntas.",
    labelNoQuestionnairesWereFound: "No se encontraron cuestionarios",
    labelNotAnalyzed: "No analizado",
    labelNotAllowed: "No permitido",
    labelNormalizingTheDocument: "Normalizando el documento, espera.",
    labelNumberOfPagesHasBeenExceeded: "Se ha excedido el número de páginas. Comuníquese con el administrador de su plan.",
    labelNumberOfQuestionsHasBeenExceeded: "Se ha excedido el número de preguntas. Comuníquese con el administrador de su plan.",
    labelOutput: "Salida",
    labelOrder: "Orden",
    labelOwner: "Dueño",
    labelPreparingTheDocument: "Preparando el documento..",
    labelPdfBack: "Volver a PDF",
    labelQueryWithoutHistory: "Consulta sin historial",
    labelQuestions: "Preguntas",
    labelQuestionAlreadyExists: "Esta pregunta ya existe",
    labelQuestionNotFound: "Pregunta no encontrada",
    labelQuestionnaires: "Cuestionarios",
    labelQuestionnaireSearch: "Nombre, ID, Tipo de documento o inserte un nuevo cuestionario para registrarte",
    labelQuestionnaireAppliedSuccessfully: "El cuestionario ha sido aplicado correctamente, espere.",
    labelQuestionnaireAndAi: "Cuestionarios e IA",
    labelReduceHistory: "Reducir la historia",
    labelReprocess: "Reprocesar",
    labelSave: "Guardar",
    labelSend: "Enviar",
    labelSendQuestion: "Enviar pregunta",
    labelSendingTheDocument: "Enviando el documento, por favor espere.",
    labelSelect: "Seleccionar",
    labelSelectAFile: "Seleccione un archivo.",
    labelSelectAnItemFromTheList: "Seleccione un elemento de la lista.",
    labelSelected: "Seleccionada",
    labelSelecteds: "Seleccionadas",
    labelSelectQuestions: "Seleccionar preguntas",
    labelSelectQuestionnaire: "Seleccione uno cuestionario",
    labelSearchDocument: "Buscar documento",
    labelSearchTypes: "Buscar tipos",
    labelSearchQuestion: "Buscar pregunta",
    labelSearchQuestionnaire: "Buscar cuestionario",
    labelSignOut: "Desconectar",
    labelStatus: "Estado",
    labelShowingToTotal: "del",
    labelShowingFilesUpload: "archivos subidos",
    labelStandardizedFullText: "Texto completo normalizado",
    labelTypes: "Tipos",
    labelTextCopiedToClipboard: "Texto copiado al portapapeles.",
    labelThisActionCannotBeUndone: "Esta acción no se puede deshacer. <br/> ¿Está seguro de que desea <br/> eliminarlo?",
    labelTypeNameOrId: "Nombre, ID o introduce un nuevo tipo para registrarte",
    labelTheFileMayBeCorrupt: "El archivo puede estar <br/> corrupto o tener un error. <br/> Por favor, inténtelo de nuevo.",
    labelTheFileMayBeUnreadableOrHaveAnError: "El archivo puede ser <br/> ilegible o tener un error. <br/> Por favor, inténtelo de nuevo.",
    labelThereIsNotEnoughCredit: "No hay suficiente crédito para aplicar todas las preguntas de este cuestionario.",
    labelTypeDoc: "Tipo de Documento",
    labelUpload: "Subir",
    labelUploadPdf: "Arrastre y suelte sus archivos PDF a continuación para empezar o haga clic en el área para navegar",
    labelUploadFailure: "Fallo en la carga",
    labelUploadedFiles: "Todos los archivos subidos",
    labelUploadedFilesError: "Fallo al cargar archivos!",
    labelUploadComplete: "Carga completa",
    labelUploadError: "Algo va mal",
    labelViewQuestions: "Ver preguntas",
    labelViewHistory: "Ver historial",
    labelYouAreAboutToDeleteDocument: "Estás a punto de eliminar <br/> el documento del sistema",
    labelYouAreAboutToDeleteDocumentType: "Estás a punto de eliminar <br/> un tipo de documento del sistema",
    labelYouAreAboutToDeleteDocumentQuery: "Estás a punto de eliminar <br/> el historial de consultas del sistema",
    labelYouAreAboutToDeleteQuestion: "Estás a punto de eliminar <br/> una pregunta del sistema",
    labelYouAreAboutToDeleteQuestionnaire: "Estás a punto de eliminar <br/> un cuestionario del sistema",
    labelRemoveAllDropzone: "Eliminar archivos de dropzone",
    labelRemoveAllFilesDropzone: "¿Eliminar todos los archivos de la cola?",
    labelThisActionRemoveAllFiles: "Esta acción eliminará todos los archivos seleccionados para cargar",
    labelCaution: "Atención!",
    labelNotReloadThePage: "Para evitar interrumpir el proceso de carga, no recargues la página",
    labelFailed: "fallido!",
}

export default spanish;
